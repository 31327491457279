<template>
  <div class="app home" @mousemove="move($event)">
    <Navigation/>
    <div class="home-content" ref="contentDom">
      <div class="background" :style="{ marginTop: topOffset, marginLeft: leftOffset }"></div>
    </div>
    <h1 class="welcome" @click="click()" :style="{ color: c, top: y, left: x }">Welcome To Assistant Bot Web UI</h1>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Navigation from '../../components/HomeNav.vue'

export default {
  name: 'Home',
  components: {
    Navigation
  },
  setup() {
    const data = reactive({
      c: '',
      x: '',
      y: '',
      pageWidth: document.body.clientWidth,
      pageHeight: document.body.clientHeight,
      topOffset: '',
      leftOffset: ''
    })

    const move = ev => {
      const x = ev.clientX
      const y = ev.clientY

      const canOffsetX = data.pageWidth * 0.05
      const canOffsetY = data.pageHeight * 0.05

      const halfWidth = data.pageWidth / 2
      const halfHeight = data.pageHeight / 2

      // 背景 move
      if (x <= halfWidth) {
        data.leftOffset = canOffsetX - canOffsetX * (x / halfWidth) + 'px'
      } else {
        data.leftOffset = '-' + canOffsetX * ((x - halfWidth) / halfWidth) + 'px'
      }
      if (y <= halfHeight) {
        data.topOffset = canOffsetY - canOffsetY * (y / halfHeight) + 'px'
      } else {
        data.topOffset = '-' + canOffsetY * ((y - halfHeight) / halfHeight) + 'px'
      }

      // 文字 move
      // if (x >= 250 && x <= data.pageWidth - 250) {
      //   data.x = x + 'px'
      // }
      // if (y >= 90 + 20 && y <= data.pageHeight - 20) {
      //   data.y = y + 'px'
      // }
    }

    const click = () => {
      const list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f']
      let color = ''
      for (let i = 0; i < 6; i++) {
        color += list[Math.floor(Math.random() * 16)]
      }
      data.c = '#' + color
    }

    return { ...toRefs(data), move, click }
  }
}
</script>

<style lang="scss" scoped>
.home {
  background: #fff;
  position: relative;
  &-content {
    overflow: hidden !important;
    .background {
      width: 120%;
      height: 120%;
      position: absolute;
      top: -10%;
      left: -10%;
      z-index: 0;
      opacity: 0.5;
      filter: blur(5px);
      background: no-repeat center center / cover url('../../assets/img/bg.png');
    }
  }
  .welcome {
    color: #fff;
    // width: 500px;
    // height: 40px;
    text-align: center;
    position: absolute;
    top: 55%;
    left: 50%;
    user-select: none;
    white-space: nowrap;
    margin: -20px 0 0 -250px;
  }
}
</style>
